import React from "react"
import {graphql} from "gatsby"
import {injectIntl, useIntl} from "gatsby-plugin-react-intl"

import Layout from "../../components/layout"
import Projects from "../../components/templates/projectsTemplate";

const SoftwarePage = ({data}) => {
    const intl = useIntl()
    const lang = intl.locale
    let mathProjects = [];
    if (lang === "ru") {
        mathProjects = data.ru_projects.edges;
    } else if (lang === "en") {
        mathProjects = data.en_projects.edges;
    }

    return (
        <Layout>
            <Projects projects={mathProjects} project_type="math"/>
        </Layout>
    )
}

export default injectIntl(SoftwarePage)

export const query = graphql`
    query{
        ru_projects: allMarkdownRemark(filter: {frontmatter: {content_type: {eq: "project"}, project_type: {eq: "math"}, published: {ne: false}, language: {eq: "ru"}}},
            sort: {fields: [frontmatter___order], order: ASC}){
            edges{
                node{
                    html
                    frontmatter{
                        shortTitle
                        title
                        id
                    }
                }
            }}

        en_projects: allMarkdownRemark(filter: {frontmatter: {content_type: {eq: "project"}, project_type: {eq: "math"}, published: {ne: false}, language: {eq: "en"}}},
            sort: {fields: [frontmatter___order], order: ASC}){
            edges{
                node{
                    html
                    frontmatter{
                        shortTitle
                        title
                        id
                    }
                }
            }}
    }
`
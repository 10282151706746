import React from "react"
import {FormattedMessage} from "react-intl"
import {Link} from "gatsby-plugin-react-intl"
import Parser from "html-react-parser"
import ProjectsNavbar from "../projectsNavbar";

export default function Projects({projects, project_type}) {
    return (
        <>
            <ProjectsNavbar project_type={project_type}/>

            <h1 style={{marginTop: `20px`}}><FormattedMessage id={project_type + ".title"}/></h1>
            <p style={{marginBottom: `5px`}}><FormattedMessage id={project_type + ".description"}/></p>

            <ul>
                {projects.map(({node}) => {
                    const link = node.frontmatter.shortTitle;
                    const id = node.frontmatter.id;
                    return (
                        <li key={id}><Link to={`/projects/${project_type}#${id}`}>{link}</Link></li>
                    )
                })}
            </ul>

            {projects.map(({node}) => {
                const title = node.frontmatter.title;
                const id = node.frontmatter.id;
                return (
                    <div className="row" id={id} key={id}>
                        <div className="col-lg-12">
                            <hr/>
                            <h2 id={id}>{title}</h2>
                            <div>{Parser(node.html)}</div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
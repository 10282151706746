import {Link} from "gatsby-plugin-react-intl";
import {FormattedMessage} from "react-intl";
import React from "react";


export default function ProjectsNavbar({project_type}) {
    function navClasses(nav) {
        if (project_type === nav) {
            return "nav-link active"
        } else {
            return "nav-link"
        }
    }

    return (
        <>
            <ul id="project-navbar" className="nav nav-tabs">
                <li className="nav-item">
                    <Link id="project_physics" className={navClasses("physics")} to="/projects/physics">
                        <FormattedMessage id="physics.bc_title"/>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link id="project_education" className={navClasses("education")} to="/projects/education">
                        <FormattedMessage id="education.bc_title"/>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link id="project_math" className={navClasses("math")} to="/projects/math">
                        <FormattedMessage id="math.bc_title"/>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link id="project_software" className={navClasses("software")} to="/projects/software">
                        <FormattedMessage id="software.bc_title"/>
                    </Link>
                </li>
            </ul>
        </>
    )
}